<template>
  <div>
    <v-card>
      <v-card-title
        class="justify-center"
        style="
          background-color: #006a4e;

          color: aliceblue;
        "
      >
        Year Report</v-card-title
      >
   
      <!-- <v-layout row-wrap>
        <v-flex xs3 md3>
          <v-select
            v-model="period"
            :items="periodOption"
            label="مدة التقرير"
            persistent-hint
            required
          ></v-select>
        </v-flex>
      </v-layout> -->
      <v-container style="text-align: center">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <apexchart :series="series" :options="options"></apexchart>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
// import AreaDetails1 from './area-details.vue';
// import areadetails from "./areadetails.vue";
export default {
  name: "market-segmentation",

  components: {
    // areadetails
  },

  data() {
    return {
      Allarea: [],
      AreaId: "",
      periodOption: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      period: "",
      years: [],
      year: 0,
      data: {
        actualSold: [],
        offeredSold: [],
        production: [],
        totalOfferedCement: [],
      },

      options: {
        dataLabels: {
          enabled: true,
        },
        chart: {
          width: 1000,
          height: 350,
        },

        xaxis: {
          categories: [
            2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
            2027,2028
          ],
        },
      },
    };
  },
  computed: {
    series() {
      let series = [
        {
          type: "column",
          name: "المبيع الفعلي",
          data: this.data.actualSold,
        },
        {
          type: "column",
          name: "الكمية المعروضة الكلية",
          data: this.data.totalOfferedCement,
        },
        {
          type: "column",
          name: "الكمية المتبقية",
          data: this.data.offeredSold,
        },
        {
          type: "column",
          name: "كمية الانتاج الكلي",
          data: this.data.production,
        },
      ];
      return series;
    },
    seriespredicted() {
      let series = [
        {
          type: "column",
          name: "actualSold",
          data: this.datapredicted.actualSold,
        },
        {
          type: "column",
          name: "totalOfferedCement",
          data: this.datapredicted.totalOfferedCement,
        },
        {
          type: "column",
          name: "offeredSold",
          data: this.datapredicted.offeredSold,
        },
        {
          type: "column",
          name: "totalOfferedCement",
          data: this.datapredicted.production,
        },
      ];
      return series;
    },
  },

  //   watch:{
  //       'period':function(){
  //           this.getYearTotal(this.period)
  //       }
  //   },

  created() {
    var d = new Date();

    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.getYears();
    this.getYearTotal(6);
  },

  methods: {
    getYears() {
      // let y = 0;
      // while (y <= 5) {
      //   this.years.push(this.year + y);
      //   y++;
      // }
    },

    async getYearTotal(period) {
      let url = `YearReport/TotalReportPerPeriod?period=${period}`;
      const response = await this.ApiService.get(url);

      response.data.responseData.oldYearReports.forEach((el) => {
        this.data.actualSold.push(el.actualSold);
        this.data.offeredSold.push(el.offeredSold);
        this.data.production.push(el.production);
        this.data.totalOfferedCement.push(el.totalOfferedCement);
      });
     
      response.data.responseData.predictedYearReports.forEach((el) => {
        this.data.actualSold.push(el.actualSold);
        this.data.offeredSold.push(el.offeredSold);
        this.data.production.push(el.production);
        this.data.totalOfferedCement.push(el.totalOfferedCement);
      });
    },
  },
};
</script>

<style scoped>
.flex.xs3.md3 {
  padding: 40px;
}
.row.tables {
  margin-top: 59px;
}
label {
  font-size: 25px;

  font-style: oblique;
  color: blueviolet;
}
</style>
